//imports
import React from "react"
import { Link } from "gatsby"

//svgImport
import byplayplay from '../../assets/byplayplay.svg';

//styles
import heroStyles from "./sectionsStyles/heroStyles.module.scss"

const Hero = ({triggerDate,conferencesRefs}) => {

  const scrollToSection = (conferencesRefs,i) => {
    const selectedConf = conferencesRefs.current[i];
    selectedConf.scrollIntoView({ behavior: 'smooth',block: 'center' })
  }

  return(
    <section className={`component ${heroStyles.heroComponent}`}>
      <div className={`container`}>

        <div className={`row ${heroStyles.titleRow}`}>
            <div className={`col-12`}>
                <p className={`text-center h3Style ${heroStyles.eventDate}`} ref={triggerDate}>15 000 inscriptions en 2021 !</p>
            </div>
        </div>

        <div className={`row justify-content-center ${heroStyles.titleRow} `}>
            <div className={`col-auto ${heroStyles.h1Col}`}>
                <h1 className={`text-center d-inline`}>Content Summit<span><img src={byplayplay} alt="by Playplay" className={`d-block img-fluid`}/></span> </h1>
              </div>
        </div>

        <div className={`row ${heroStyles.statusFinishedRow}`}>
            <div className={`col-12`}>
                <span className={`d-block ${heroStyles.separator}`}></span>
                <p className={`d-block text-center ${heroStyles.finishedTitle}`}>l'édition 2021 est terminée </p>
            </div>
        </div>

        <div className={`row ${heroStyles.titleRow}`}>
            <div className={`col-12`}>
                {/* <p className={`d-block text-center ${heroStyles.introTxt}`}>8 conférences de 30 minutes avec les meilleurs experts <br/>pour parler Content, Social Media, Podcast, Vidéo, Storytelling... <br/> Première édition, 100% en ligne, 100% gratuit.</p> */}
                <p className={`d-block text-center ${heroStyles.introTxt}`}>Rendez-vous en 2022 ! En attendant, découvrez les replays des 8 conférences 2021.</p>
            </div>
        </div>

        <div className={`row justify-content-center ${heroStyles.ctaRow}`}>
            <div className={`col-10 text-center`}>
              <Link to={"/"} 
                className={`cta_button`} 
                onClick={() => scrollToSection(conferencesRefs,0)} 
                onKeyDown={() => scrollToSection(conferencesRefs,0)} 
                role = "button" 
                aria-label="Got to Section" 
                tabIndex={0} 
            >Accéder aux replays</Link>
            </div>
        </div>

      </div>
    </section>
  )
}

export default Hero
