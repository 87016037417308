//react imports
import React, { useRef, useEffect } from "react";
import { graphql } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

//modules
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Subscribe from "../components/Subscribe"
import Header from "../components/Header"
import Hero from "../components/sections/Hero"
import FinishedEvent from "../components/sections/FinishedEvent"
import SpeakersSlider from "../components/sections/SpeakersSlider"
import Conference from "../components/sections/Conference"

require("typeface-noto-sans")

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}


const IndexPage = ({data}) =>{

  const conferences = [
    {
      name:"Grégoire Gambatto",
      bio:"CEO de Germinal",
      speakerImageM:data.GregoireGambattoM.childImageSharp.fluid,
      speakerImageMM:data.GregoireGambattoMM.childImageSharp.fluid,
      speakerImageS:data.GregoireGambattoS.childImageSharp.fluid,
      speakerImageXS:data.GregoireGambattoXS.childImageSharp.fluid,
      confDesc:"Comment j'ai atteint 20 millions de personnes <br> en 2020 sur LinkedIn.",
      timeConf:"10.00 - 10.30",
      confLiveStorm:"https://info.playplay.com/fr/content-summit-2021-replay-gr%C3%A9goire-gambatto",
      // confLiveStorm:"https://app.livestorm.co/playplay-3/content-summit-2021-gregoire-gambatto",
      alignText:"right",
    },
    {
      name:"Justine Hutteau",
      bio:"Co-fondatrice de Respire",
      speakerImageM:data.JustineHutteauM.childImageSharp.fluid,
      speakerImageMM:data.JustineHutteauMM.childImageSharp.fluid,
      speakerImageS:data.JustineHutteauS.childImageSharp.fluid,
      speakerImageXS:data.JustineHutteauXS.childImageSharp.fluid,
      confDesc:"Comment Respire a construit sa notoriété de marque grâce à une stratégie de contenu inédite.",
      timeConf:"10.30 - 11.00",
      confLiveStorm:"https://info.playplay.com/fr/content-summit-2021-replay-justine-hutteau",
      // confLiveStorm:"https://app.livestorm.co/playplay-3/content-summit-2021-justine-hutteau",
      alignText:"left",
    },
    {
      name:"Marie Ouvrard",
      bio:"Chief Content Officer chez Welcome to the Jungle",
      speakerImageM:data.MarieOuvrardM.childImageSharp.fluid,
      speakerImageMM:data.MarieOuvrardMM.childImageSharp.fluid,
      speakerImageS:data.MarieOuvrardS.childImageSharp.fluid,
      speakerImageXS:data.MarieOuvrardXS.childImageSharp.fluid,
      confDesc:"Comment se développer professionnellement grâce au contenu ?",
      timeConf:"11.00 - 11.30",
      confLiveStorm:"https://info.playplay.com/fr/content-summit-2021-replay-marie-ouvrard",
      // confLiveStorm:"https://app.livestorm.co/playplay-3/content-summit-2021-marie-ouvrard",
      alignText:"right",
    },
    {
      name:"Thibaut Machet",
      bio:"CEO de PlayPlay",
      speakerImageM:data.ThibautMachetM.childImageSharp.fluid,
      speakerImageMM:data.ThibautMachetMM.childImageSharp.fluid,
      speakerImageS:data.ThibautMachetS.childImageSharp.fluid,
      speakerImageXS:data.ThibautMachetXS.childImageSharp.fluid,
      confDesc:"8 contenus vidéo que vous devez lancer impérativement en 2021.",
      timeConf:"11.30 - 11.45",
      confLiveStorm:"https://info.playplay.com/fr/content-summit-2021-replay-thibaut-machet",
      // confLiveStorm:"https://app.livestorm.co/playplay-3/content-summit-2021-thibaut-machet",
      alignText:"left",
    },
    {
      name:"Tom Rouyres",
      bio:"Partenariats Stratégiques Médias chez Facebook",
      speakerImageM:data.TomRouyresM.childImageSharp.fluid,
      speakerImageMM:data.TomRouyresMM.childImageSharp.fluid,
      speakerImageS:data.TomRouyresS.childImageSharp.fluid,
      speakerImageXS:data.TomRouyresXS.childImageSharp.fluid,
      confDesc:"Les clés d'une stratégie vidéo réussie sur Facebook et Instagram.",
      timeConf:"14.00 - 14.30",
      confLiveStorm:"https://info.playplay.com/fr/content-summit-2021-replay-tom-rouyres",
      // confLiveStorm:"https://app.livestorm.co/playplay-3/content-summit-2021-tom-rouyres",
      alignText:"right"
    },
    {
      name:"Matthieu Stefani",
      bio:"Podcast Host de Génération Do It Yourself",
      speakerImageM:data.MatthieuStefaniM.childImageSharp.fluid,
      speakerImageMM:data.MatthieuStefaniMM.childImageSharp.fluid,
      speakerImageS:data.MatthieuStefaniS.childImageSharp.fluid,
      speakerImageXS:data.MatthieuStefaniXS.childImageSharp.fluid,
      confDesc:"Pourquoi vous devez lancer votre podcast en 2021.",
      timeConf:"14.30 - 15.00",
      confLiveStorm:"https://info.playplay.com/fr/content-summit-2021-replay-matthieu-stefani",
      // confLiveStorm:"https://app.livestorm.co/playplay-3/content-summit-2021-matthieu-stefani",
      alignText:"left"
    },
    {
      name:"Elise Nebout",
      bio:"Directrice de l’école Les Mots",
      speakerImageM:data.EliseNeboutM.childImageSharp.fluid,
      speakerImageMM:data.EliseNeboutMM.childImageSharp.fluid,
      speakerImageS:data.EliseNeboutS.childImageSharp.fluid,
      speakerImageXS:data.EliseNeboutXS.childImageSharp.fluid,
      confDesc:"Les recettes secrètes de l'écriture et du copywriting.",
      timeConf:"15.00 - 15.30",
      confLiveStorm:"https://info.playplay.com/fr/content-summit-2021-replay-elise-nebout",
      // confLiveStorm:"https://app.livestorm.co/playplay-3/content-summit-2021-elise-nebout",
      alignText:"right"
    },
    {
      name:"Charles Alf Lafon",
      bio:"Content Strategist chez TBWA\\Corporate",
      speakerImageM:data.CharlesAlfLafonM.childImageSharp.fluid,
      speakerImageMM:data.CharlesAlfLafonMM.childImageSharp.fluid,
      speakerImageS:data.CharlesAlfLafonS.childImageSharp.fluid,
      speakerImageXS:data.CharlesAlfLafonXS.childImageSharp.fluid,
      confDesc:"Les tendances contenu : que nous réserve 2021 ?",
      timeConf:"15.30 - 16.00",
      confLiveStorm:"https://info.playplay.com/fr/content-summit-2021-replay-charles-alf-lafon",
      // confLiveStorm:"https://app.livestorm.co/playplay-3/content-summit-2021-charles-alf-lafon",
      alignText:"left"
    },
  ]

  let conferencesRefs = useRef([]);
  conferencesRefs.current = [];
  const addConfsRefs = (el) =>{
    if(el && !conferencesRefs.current.includes(el)){
      conferencesRefs.current.push(el)
    }
  }

  const headerRef = useRef(null);
  const dateRef = useRef(null);

  const triggerDate = useRef(null);
  const triggerCountdown = useRef(null);

  useEffect (() => {

    gsap.fromTo(headerRef.current, {
      autoAlpha: 0,
    }, {
      duration: 0.15,
      autoAlpha: 1,
      ease: 'none',
      scrollTrigger: {
          trigger: triggerDate.current,
          start: 'top top-=65',
          toggleActions: 'play none none reverse'
      }
    });

  }, [])

  useEffect (() => {

    gsap.fromTo(dateRef.current, {
      autoAlpha: 0,
      display: "none"
    }, {
      duration: 0.15,
      autoAlpha: 1,
      display: "flex",
      ease: 'none',
      delay: 0.2,
      scrollTrigger: {
          trigger: triggerDate.current,
          start: 'top top-=65',
          toggleActions: 'play none none reverse'
      }
    });

  }, [])

  useEffect (() => {

    conferencesRefs.current.forEach((el) =>{
      gsap.fromTo(el, {
        // autoAlpha: 0,
      },{
        duration: 0.15,
        // autoAlpha: 1,
        ease: 'none',
        scrollTrigger: {
            trigger: el,
            start: 'top center-=100',
            end: 'bottom center-=100',
            toggleActions: 'play reverse play reverse',
            toggleClass: 'highlighted'
            // markers: true
        }
      });
    })

  }, [])

  return (
    <Layout className={`layoutWrapper index`}>
      <SEO />

      {/* <div>
        <Subscribe />
      </div> */}

      <div className={`stickyHeaderWrapper`}>
        <Header headerRef={headerRef} dateRef={dateRef}/>
      </div>

      <div className={`heroWrapper`}>
        <Hero triggerDate={triggerDate} conferencesRefs={conferencesRefs}/>
      </div>

      <div className={`speakersSliderWrapper`}>
        <SpeakersSlider conferences={conferences} conferencesRefs={conferencesRefs} />
      </div>

      <div className={`counterWrapper`}>
        <FinishedEvent triggerCountdown={triggerCountdown}/>
        {/* <Counter triggerCountdown={triggerCountdown}/> */}
      </div>

      <div className={`confsWrapper`}>
        {
          conferences.map( ({name,bio,confDesc,speakerImageM,speakerImageMM,timeConf,confLiveStorm,alignText},i) =>{
            return <Conference key={i} name={name} bio={bio} confDesc={confDesc} timeConf={timeConf} confLiveStorm={confLiveStorm} alignText={alignText} speakerImageM={speakerImageM} speakerImageMM={speakerImageMM} conferenceRef={addConfsRefs}/>
          })
        }
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query {
  GregoireGambattoM: file(relativePath: { eq: "speakers/GregoireGambatto-M@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 520, quality: 75) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  JustineHutteauM: file(relativePath: { eq: "speakers/JustineHutteau-M@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 520, quality: 75) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  MarieOuvrardM: file(relativePath: { eq: "speakers/MarieOuvrard-M@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 520, quality: 75) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  ThibautMachetM: file(relativePath: { eq: "speakers/ThibautMachet-M@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 520, quality: 75) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  TomRouyresM: file(relativePath: { eq: "speakers/TomRouyres-M@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 520, quality: 75) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  MatthieuStefaniM: file(relativePath: { eq: "speakers/MatthieuStefani-M@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 520, quality: 75) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  EliseNeboutM: file(relativePath: { eq: "speakers/EliseNebout-M@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 520, quality: 75) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  CharlesAlfLafonM: file(relativePath: { eq: "speakers/CharlesAlfLafon-M@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 520, quality: 75) {
        ...GatsbyImageSharpFluid
      }
    }
  },


  GregoireGambattoMM: file(relativePath: { eq: "speakers/GregoireGambatto-M@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 320) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  JustineHutteauMM: file(relativePath: { eq: "speakers/JustineHutteau-M@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 320) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  MarieOuvrardMM: file(relativePath: { eq: "speakers/MarieOuvrard-M@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 320) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  ThibautMachetMM: file(relativePath: { eq: "speakers/ThibautMachet-M@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 320) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  TomRouyresMM: file(relativePath: { eq: "speakers/TomRouyres-M@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 320) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  MatthieuStefaniMM: file(relativePath: { eq: "speakers/MatthieuStefani-M@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 320) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  EliseNeboutMM: file(relativePath: { eq: "speakers/EliseNebout-M@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 320) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  CharlesAlfLafonMM: file(relativePath: { eq: "speakers/CharlesAlfLafon-M@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 320) {
        ...GatsbyImageSharpFluid
      }
    }
  },




  GregoireGambattoS: file(relativePath: { eq: "speakers/GregoireGambatto-Profil@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  JustineHutteauS: file(relativePath: { eq: "speakers/JustineHutteau-Profil@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  MarieOuvrardS: file(relativePath: { eq: "speakers/MarieOuvrard-Profil@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  ThibautMachetS: file(relativePath: { eq: "speakers/ThibautMachet-Profil@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  TomRouyresS: file(relativePath: { eq: "speakers/TomRouyres-Profil@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  MatthieuStefaniS: file(relativePath: { eq: "speakers/MatthieuStefani-Profil@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  EliseNeboutS: file(relativePath: { eq: "speakers/EliseNebout-Profil@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  CharlesAlfLafonS: file(relativePath: { eq: "speakers/CharlesAlfLafon-Profil@2x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },





  GregoireGambattoXS: file(relativePath: { eq: "speakers/GregoireGambatto-Profil@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  JustineHutteauXS: file(relativePath: { eq: "speakers/JustineHutteau-Profil@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  MarieOuvrardXS: file(relativePath: { eq: "speakers/MarieOuvrard-Profil@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  ThibautMachetXS: file(relativePath: { eq: "speakers/ThibautMachet-Profil@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  TomRouyresXS: file(relativePath: { eq: "speakers/TomRouyres-Profil@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  MatthieuStefaniXS: file(relativePath: { eq: "speakers/MatthieuStefani-Profil@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  EliseNeboutXS: file(relativePath: { eq: "speakers/EliseNebout-Profil@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  CharlesAlfLafonXS: file(relativePath: { eq: "speakers/CharlesAlfLafon-Profil@1x.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 144) {
        ...GatsbyImageSharpFluid
      }
    }
  }

}
`
