//imports
import React from "react"
// import moment from 'moment';

//styles
import styles from "./sectionsStyles/counterStyles.module.scss"

const FinishedEvent = ({triggerCountdown}) => {

    return(
      <section className={`component ${styles.component}`} ref={triggerCountdown}>
        <div className={`container`}>
            <div className={`row`}>
                <div className={`col-12 ${styles.finishedDescCol}`}>
                    <div className={`d-flex flex-row justify-content-center`}>
                        <p className={`text-center d-block`}>Le Content Summit est le nouveau rendez-vous annuel pour parler Content, Social Media, Podcast, Vidéo, Storytelling...</p>
                    </div>
                </div>
                <div className={`col-12 ${styles.finishedDescCol}`}>
                    <div className={`d-flex flex-row justify-content-center`}>
                        <p className={`text-center d-block ${styles.finishedDesc}`}>100% en ligne, 100% gratuit.</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
    )
}

export default FinishedEvent
