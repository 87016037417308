//imports
import React from "react"

//styles
import headerStyles from "./sections/sectionsStyles/headerStyles.module.scss"

const Header = ({headerRef, dateRef}) => (
  <header className={`component headercomponent ${headerStyles.component}`} ref={headerRef}>
    <div className={`container`}>
        <div className={`row ${headerStyles.rowDate}`} ref={dateRef}>
            <div className={`col-12`}>
              <p className={`text-center h3Style ${headerStyles.eventDate}`}>15 000 inscriptions en 2021 !</p>
            </div>
        </div>
    </div>
  </header>
)

export default Header