//imports
import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';

//hooks
import useWindowSize from "../../hooks/useWindowSize"

//assets
import startslider from "../../assets/startslider.png"
import endslider from "../../assets/endslider.png"

//modules
import Speaker from "../modules/Speaker"

//styles
import speakerSlidertyles from "./sectionsStyles/speakerSlidertyles.module.scss"

SwiperCore.use([Navigation]);

const SpeakersSlider = ({conferences,conferencesRefs}) => {
  const windowSize = useWindowSize();

  const slideAmountPerView = () => {
    let slideAmout = windowSize.width / 200;
    return slideAmout
  }

  const scrollToSection = (conferencesRefs,i) => {
    const selectedConf = conferencesRefs.current[i];
    selectedConf.scrollIntoView({ behavior: 'smooth',block: 'center' })
  }

  const slider = () =>{
    if(windowSize.width > 1700){
      return (
      <div className={`row justify-content-center ${speakerSlidertyles.speakerCardDesktopWrapper}`}>
        <div className={`col-auto ${speakerSlidertyles.speakerCard} ${speakerSlidertyles.sliderStartEnd}`}><img src={startslider} alt="start end slider" className={`${speakerSlidertyles.start}`}/></div>
        
        {
          conferences.map( ({name,bio,speakerImageS,speakerImageXS},i) =>{
            return (
            <div  key={i}
                  onClick={() => scrollToSection(conferencesRefs,i)} 
                  onKeyDown={() => scrollToSection(conferencesRefs,i)} 
                  role = "button" 
                  aria-label="Got to Section" 
                  tabIndex={0} 
                  className={`col-auto ${speakerSlidertyles.speakerCard}`}>
              <Speaker name={name} bio={bio} speakerImageS={speakerImageS} speakerImageXS={speakerImageXS}/>
            </div> 
            )
          })
        }
        
        <div className={`col-auto ${speakerSlidertyles.speakerCard} ${speakerSlidertyles.sliderStartEnd}`}><img src={endslider} alt="start end slider" className={`${speakerSlidertyles.end}`}/></div>
      </div>
      )
    }else{
      return(
        <Swiper
        spaceBetween={0}
        slidesPerView={slideAmountPerView()}
        onSlideChange={() => console.log('slide change')}
        navigation
        // onSwiper={(swiper) => console.log(swiper)}
        >
          {conferences.map( ({name,bio,speakerImageS,speakerImageXS},i) =>{
            return (
              <SwiperSlide 
                key={i}
                onClick={() => scrollToSection(conferencesRefs,i)} 
                onKeyDown={() => scrollToSection(conferencesRefs,i)} 
                role = "button" 
                aria-label="Got to Section" 
                tabIndex={0} 
                className={`${speakerSlidertyles.speakerCard}`}>
                <Speaker name={name} bio={bio} speakerImageS={speakerImageS} speakerImageXS={speakerImageXS}/>
              </SwiperSlide>
            )
          })}
          <SwiperSlide className={`${speakerSlidertyles.speakerCard} ${speakerSlidertyles.sliderStartEnd}`}><img src={endslider} alt="start end slider" className={`${speakerSlidertyles.end}`}/></SwiperSlide>
        </Swiper>
      )
    }

  }

    return(
      <section className={`component ${speakerSlidertyles.component}`}>
        <div className={`container-fluid ${speakerSlidertyles.sliderContainer}`}>
          {slider()}
        </div>
      </section>
    )
}

export default SpeakersSlider