//imports
import React from "react"
import Img from "gatsby-image"

// svgImport
import whiteArrowRight from '../../assets/white-arrow-right.svg';

//styles
import speakerStyles from "./modulesStyles/speakerStyles.module.scss"

const Speaker = ({name,bio,speakerImageS,speakerImageXS}) =>{

  const sources = [
    speakerImageXS,
    {
      ...speakerImageS,
      media: `(min-width: 480px)`,
    },
  ]

  return (
    <div className={`${speakerStyles.speakerCardInnerWrapper}`}>
      
      <div className={`d-block ${speakerStyles.ppWrapper}`}>
        <div className={`d-block ${speakerStyles.ppInnerWrapper}`}>
          <Img fluid={sources} />
          <span className={`d-block ${speakerStyles.whitearrow}`}>
            <img src={whiteArrowRight} className={`d-block img-fluid`} alt="white Arrow Go to Conference"/>
          </span>
        </div>
      </div>

      <p className={`bold text-center ${speakerStyles.name}`}>{name}</p>
      <p className={`text-center ${speakerStyles.bio}`} dangerouslySetInnerHTML={{ __html: bio }}></p>
    </div>
  )
};

export default Speaker