import React from "react";
import Img from "gatsby-image"

//styles
import conferencesStyles from "./sectionsStyles/conferencesStyles.module.scss"

const Conference = ({name,bio,speakerImageM,speakerImageMM,timeConf,confDesc,confLiveStorm,alignText,conferenceRef}) => {

  const sources = [
    speakerImageMM,
    {
      ...speakerImageM,
      media: `(min-width: 480px)`,
    },
  ]

  return(
    <section className={`component conferenceComponent ${conferencesStyles.component}`} ref={conferenceRef}>
      <div className={`container confInnerWrapper ${conferencesStyles.confContainer}`}>
        <div className={`confInnerWrapper ${conferencesStyles.confInnerWrapper}`}>
          <div className={`row no-gutters ${alignText === 'left' ? `flex-row-reverse ${conferencesStyles.reversed}` : `flex-row ${conferencesStyles.notreversed}` }`}>

            <div className={`col-12 col-lg-6 d-flex flex-column justify-content-center ${conferencesStyles.imgCol}`}>
              <div className={`d-block ${conferencesStyles.imgWrapper}`}>
                <Img fluid={sources} />
              </div>
            </div>

            <div className={`col-12 col-lg-6 ${conferencesStyles.textCol}`}>
              <p className={`d-block ${conferencesStyles.time}`}>{timeConf}</p>
              <p className={`d-block h2Style ${conferencesStyles.name}`}>{name}</p>
              <p className={`d-block h3Style ${conferencesStyles.bio}`} dangerouslySetInnerHTML={{ __html: bio }}></p>
              <p className={`d-block ${conferencesStyles.confname}`} dangerouslySetInnerHTML={{ __html: confDesc }}></p>

              {/* <a href={confLiveStorm} className={`cta_button`} target="_blank" rel="noreferrer">S'inscrire</a> */}
              <a href={confLiveStorm} className={`cta_button`} target="_blank" rel="noreferrer">Regarder le replay</a>
            </div>
          </div>
        </div>
        <div className={`confHighlight ${conferencesStyles.confHighlight}`}></div>
      </div>
    </section>
  )
}

export default Conference
